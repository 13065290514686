import React, { createContext, useContext, useState, useEffect } from 'react';
import { isAuthenticatedViaCookie, setAuthenticationCookie } from '../../cookie/cookie';
import { ApiService } from './ApiService';

const IsAuthenticated = createContext({
    isAuth: false,
    permission: "",
    pseudo: "",
    email: "",
    loading: true
});

export const useAuth = () => {
    return useContext(IsAuthenticated);
};

export const AuthProvider = ({ children }) => {
    const [isAuth, setIsAuth] = useState(false);
    const [permission, setPermission] = useState(false);
    const [pseudo, setPseudo] = useState(false);
    const [email, setEmail] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAuthentication = async () => {
          try {
            const data = await ApiService.get('/v1/isAuthenticated');
            setIsAuth(data.isAuthenticated);
            setPermission(data.permission);
            setPseudo(data.pseudo);
            setEmail(data.email);
            setAuthenticationCookie({ info: data, expiration: new Date(new Date().getTime() + (24 * 60 * 60 * 1000)) });
            if (!data.isAuthenticated && window.location.pathname !== "/login") {
              window.location.href = "/login";
            }
          } catch (error) {
            const cookieAuth = isAuthenticatedViaCookie();
            if (cookieAuth && cookieAuth["data"]) {
              setIsAuth(cookieAuth["data"].isAuthenticated);
              setPermission(cookieAuth["data"].permission);
              setPseudo(cookieAuth["data"].pseudo);
            } else {
              setIsAuth(false);
            }
          } finally {
            setLoading(false);
          }
        };
        checkAuthentication();
      }, []);

    return (
        <IsAuthenticated.Provider value={{ isAuth, permission, pseudo, email, loading }}>
            {children}
        </IsAuthenticated.Provider>
    );
};

export { IsAuthenticated };